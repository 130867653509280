import ServiceType from "@olivahealth/graphql-server/src/domain/value-objects/ServiceType";
import { useUserData } from "../services/contexts/UserDataContext";

export default function useCanAccessHybridCare(): boolean {
  const { data: user } = useUserData();

  return Boolean(
    user?.organisation?.plan?.services.includes(ServiceType.HYBRID_CARE),
  );
}
