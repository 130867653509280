import { Button, Dialog, Text } from "@olivahealth/oli-ui";
import { useRouter } from "next/router";
import {
  ABOVE_CASENESS_SCORE_THRESHOLD,
  BELOW_CASENESS_SCORE_THRESHOLD,
} from "@olivahealth/constants";
import { formatMinimalDateFullMonthYear } from "../../../utils/date/format";
import { TFunction } from "../../../hooks/useTranslation";
import FocusAreaScoreDisclosure from "../../molecules/FocusAreaScoreDisclosure";
import { WellbeingProfileScoreDisplay_wellbeingProfileResults$data } from "./__generated__/WellbeingProfileScoreDisplay_wellbeingProfileResults.graphql";

interface Props {
  t: TFunction;
  data: WellbeingProfileScoreDisplay_wellbeingProfileResults$data[0];
  isOpen: boolean;
  onClose: () => void;
  isExternal?: boolean;
  showUpdateButton?: boolean;
}

export default function CurrentScoreBreakdownDialog({
  t,
  data,
  onClose,
  isOpen,
  isExternal = false,
  showUpdateButton = true,
}: Props) {
  const { push } = useRouter();
  const wellbeingScore = Math.ceil(data.wellbeingScore);

  return (
    <Dialog size="xl" isOpen={isOpen} onClose={onClose}>
      <div className="w-full items-center flex flex-col gap-y-2">
        <Text weight="bold" size="xl">
          {t(
            isExternal
              ? "scoreDisplay.currentTitleExternal"
              : "scoreDisplay.yourCurrentWellbeingProfile",
          )}
        </Text>
        <Text color="secondary" gutter="md">
          {t("scoreDisplay.lastUpdated", {
            date: formatMinimalDateFullMonthYear(data.createdAt),
          })}
        </Text>
        <div className="w-full flex flex-col mb-3">
          <Text color="secondary" weight="medium" size="sm" gutter="sm">
            {t("scoreDisplay.overallWellbeingScore")}
          </Text>
          <span className="flex items-center justify-between mb-5">
            <Text weight="bold" size="xl">
              {t(
                `scoreDisplay.overTimeTabContent.scoreTitle.${getScoreTitle(wellbeingScore)}`,
              )}
            </Text>
            <Text size="sm">{`${wellbeingScore}%`}</Text>
          </span>
          <Text color="secondary" weight="medium" size="sm" gutter="md">
            {t("scoreDisplay.areaBreakdown")}
          </Text>
          <div className="flex flex-col gap-y-7 mb-3">
            {data.scores.map(({ score, focusArea, answers }) => (
              <FocusAreaScoreDisclosure
                score={score}
                focusArea={focusArea}
                key={focusArea}
                answers={answers}
                variant="dark"
                contentToShow={isExternal ? "answers" : "description"}
              />
            ))}
          </div>
        </div>
        {showUpdateButton && (
          <div className="z-10">
            <Button
              width="full"
              variant="secondary"
              onClick={() => push("/wellbeing")}
            >
              {t("scoreDisplay.updateWellbeingProfile")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

function getScoreTitle(score: number): string {
  if (score < ABOVE_CASENESS_SCORE_THRESHOLD + 1) {
    return "low";
  }

  if (
    score >= ABOVE_CASENESS_SCORE_THRESHOLD + 1 &&
    score < BELOW_CASENESS_SCORE_THRESHOLD + 1
  ) {
    return "moderate";
  }

  return "high";
}
